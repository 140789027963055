

import { Vue, Component } from "vue-property-decorator";

import { CustomerService } from "@/services/customer";
import { CustomerDTO } from "@/services/customer/customer.dto";

const BP_NUMBER = "BP Number";
const METER_NUMBER = "Meter Number";

@Component
export default class Search extends Vue {
  loading = false;
  search = "";
  searchByItems = [BP_NUMBER, METER_NUMBER];
  searchBy = BP_NUMBER;

  created() {
    this.$store.commit("liveData/setSelectedActivity", 6);
    this.$store.commit("liveData/setSelectedActivityName", "Search");
  }

  async searchForCustomer() {
    this.loading = true;
    try {
      let bpNumber: string | undefined;
      let deviceSerialNumber: string | undefined;

      if (this.searchBy === BP_NUMBER) {
        bpNumber = this.search;
      } else {
        deviceSerialNumber = this.search;
      }

      const customer: CustomerDTO = await CustomerService.searchCustomer(
        deviceSerialNumber,
        bpNumber
      );

      this.$router.push("/customer/" + customer.customerId);
    } catch (err) {
      alert("No customer found");
      console.log("error", err);
    }
    this.loading = false;
  }
}
