import * as Repository from "./customer.repository";
import { CustomerDTO } from "./customer.dto";

async function searchCustomer(
  deviceSerialNumber?: string,
  bpNumber?: string
): Promise<CustomerDTO> {
  const customer: CustomerDTO = await Repository.searchCustomer({
    bpNumber,
    deviceSerialNumber
  });

  return customer;
}

export const CustomerService = { searchCustomer };
